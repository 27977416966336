$(document).ready(function() {

    // Variables
    const HOSTEL_SELECT_WRAPPER = $(".which-hotel-wrapper");
    const REFERENCE_BY_SELECT = $("#referencedBySelect");

    // Select starts hidden
    HOSTEL_SELECT_WRAPPER.hide();

    // Function to Show/Hide which-hotel-wrapper If "Hotel/Hostel" is selected
    function showHideHostelSelect() {
        REFERENCE_BY_SELECT.change(function() {

            if ( REFERENCE_BY_SELECT.val() === "Hostel/Hotel" ) {
                HOSTEL_SELECT_WRAPPER.show();
                // HOSTEL_SELECT_WRAPPER.removeClass('hidden');
                // HOSTEL_SELECT_WRAPPER.addClass('visible');
            } else {
                HOSTEL_SELECT_WRAPPER.hide();
                // HOSTEL_SELECT_WRAPPER.removeClass('visible');
                // HOSTEL_SELECT_WRAPPER.addClass('hidden');
            }

        })
    }

    // Function Calls
    showHideHostelSelect();
});