// Shows/Hides Tour Form
document.addEventListener("turbolinks:load", function() {

    // Variables
    let toggleCounter = 0
    let tourForm = $('.tour-form-wrapper') // Tour Form Block
    let formToggle = $('#tourFormToggle') // Form Button Toggler
    let formBody = $('.tour-form-body') // Form Body

    // Form Toggle Click
    formToggle.click(function()
    {
        if (toggleCounter === 0)
        {
            tourForm.addClass("active")
            formBody.addClass("shown")
            formBody.removeClass("hidden")
            toggleCounter = 1
        }
        else
        {
            tourForm.removeClass("active")
            formBody.addClass("hidden")
            formBody.removeClass("shown")
            toggleCounter = 0
        }

    })

});