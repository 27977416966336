// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

// jQuery Keywords declared
window.jQuery = $;
window.$ = $;

// Popper (Bootstrap dependency)
require("@popperjs/core")

// Bootstrap 5
import "bootstrap"
// Import the specific modules you may need (Modal, Alert, etc)
import { Tooltip, Popover } from "bootstrap"

// Application Stylesheet (scss)
import "../stylesheets/application";

// Import custom JS
import "./initialize";
import  "./form_toggle";
import "./sidebar";
import "./smooth_scroll";
import "./tour_navbar";
import "./toggle_private_tour";
import "./show_hide_hostel_select";


Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")


import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

document.addEventListener('turbolinks:load', function() {
  const calendarEl = document.getElementById('calendar');
  
  if (calendarEl) {
    const cityPrivateTourId = calendarEl.getAttribute('data-tour-id'); // Obtén el ID desde el data attribute
  
    const calendar = new Calendar(calendarEl, {
      plugins: [dayGridPlugin, interactionPlugin],
      initialView: 'dayGridMonth',
      selectable: true,
      events: `/city_private_tours/${cityPrivateTourId}/blocked_dates`, // Usa el ID de la URL correctamente
      select: function(info) {
        const confirmBlock = confirm(`Do you want to toggle the blocked status for this date: ${info.startStr}?`);
        if (confirmBlock) {
          fetch(`/city_private_tours/${cityPrivateTourId}/toggle_blocked_date`, { // URL con el ID de la URL correctamente
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            body: JSON.stringify({ date: info.startStr })
          }).then(() => {
            calendar.refetchEvents();
          });
        }
      }
    });
    calendar.render();
  }
});

