// Show/Hide Private Form from Free & Shared Tours Form
$(document).on('turbolinks:load', function() {
    const FREE_TOUR_PRIVATE_TOUR_SWITCH = $("#freeTourPrivateTourSwitch")
    const SHARED_TOUR_PRIVATE_TOUR_SWITCH = $("#sharedTourPrivateTourSwitch")
    const FREE_TOUR_FORM = $("#freeTourForm")
    const SHARED_TOUR_FORM = $("#sharedTourForm")
    const PRIVATE_TOUR_FORM = $("#privateTourForm")

    // Switch toggle for Free Tours
    FREE_TOUR_PRIVATE_TOUR_SWITCH.change(function() {
        if ($(this).is(':checked')) {
            console.log('Checked')
            FREE_TOUR_FORM.addClass('display-none')
            PRIVATE_TOUR_FORM.removeClass('display-none')
        } else {
            console.log('Unchecked')
            FREE_TOUR_FORM.removeClass('display-none')
            PRIVATE_TOUR_FORM.addClass('display-none')
        }
    })

    // Switch toggle for Shared Tours
    SHARED_TOUR_PRIVATE_TOUR_SWITCH.change(function() {
        if ($(this).is(':checked')) {
            console.log('Checked')
            SHARED_TOUR_FORM.addClass('display-none')
            PRIVATE_TOUR_FORM.removeClass('display-none')
        } else {
            console.log('Unchecked')
            SHARED_TOUR_FORM.removeClass('display-none')
            PRIVATE_TOUR_FORM.addClass('display-none')
        }
    })


})