document.addEventListener("turbolinks:load", function() {

    // Function Calls
    sidebarToggle()

    // Functions
    function sidebarToggle() {
        let counter = 0

        $('#sidebarToggle').click(function() {
            // Constants
            const SIDEBAR_TOGGLE_ICON_OPEN = "#sidebarToggleIconOpen"
            const SIDEBAR_TOGGLE_ICON_CLOSE = "#sidebarToggleIconClose"

                if (counter === 0) {
                    $('.private-sidebar-wrapper').addClass('compressed');
                    $('.private-content-wrapper').addClass('expanded');

                    $(SIDEBAR_TOGGLE_ICON_OPEN).removeClass('display-none')
                    $(SIDEBAR_TOGGLE_ICON_OPEN).addClass('display-inline-block')
                    $(SIDEBAR_TOGGLE_ICON_CLOSE).addClass('display-none')
                    $(SIDEBAR_TOGGLE_ICON_CLOSE).removeClass('display-inline-block')

                    counter = 1
                }
                else if (counter === 1) {
                    $('.private-sidebar-wrapper').removeClass('compressed');
                    $('.private-content-wrapper').removeClass('expanded');

                    $(SIDEBAR_TOGGLE_ICON_OPEN).addClass('display-none')
                    $(SIDEBAR_TOGGLE_ICON_OPEN).removeClass('display-inline-block')
                    $(SIDEBAR_TOGGLE_ICON_CLOSE).removeClass('display-none')
                    $(SIDEBAR_TOGGLE_ICON_CLOSE).addClass('display-inline-block')

                    counter = 0
                }

            }
        )

    }


});