// Adds/Removes 'active' class from nav-item in Tour Secondary Navbar
document.addEventListener("turbolinks:load", function() {
    let tourNavItem = $('.tour-nav-item')

    tourNavItem.click(function(e){
        $(this).addClass('active');
        $(this).siblings().each(function(){
            $(this).removeClass('active') ;
        });
    });

})